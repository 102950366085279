import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import CompetenciasUpdate from "@/components/layout/DiseñoCurricular/CompetenciasUpdate.vue";
import ContenidoUpdate from "@/components/layout/DiseñoCurricular/ContenidoUpdate.vue";
import ContenidoAnaliticoUpdate from "@/components/layout/DiseñoCurricular/ContenidoAnaliticoUpdate.vue";
import Resumen from "@/components/layout/DiseñoCurricular/Resumen.vue";
window.$ = window.jQuery = require("jquery");

export default {

    name: "InformacionGeneral",
    data() {

        let ss = new MainService();
        return {
            msg: "InformacionGeneral",
            ss: ss,
            competencia: {},
            competenciaStatus:{},
            criterioSeleccion: {},
            criterios: {},
            contenido: {},
            materia: {},
            materiaMalla: {},
            contenidoshow: {},
            contenidoInf: {},
            criterioInf: {},
            criterioCargaHoraria: {},
            criterioCYP: {},
            contenidoAnalitico: {},
            contenidoAnaliticoInf: {},
            count: 0,
            seleccion: 'IG',
            seleccionCriterioMenuUpdate: 'CompetenciasUpdate',
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout: 'InformacionGeneral',
            layout2: 'CompetenciasUpdate',
            parametro: {},
            storeCompetencia1: {},
            auth: {}
        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        showInfoGeneral() {
            this.$refs['view-infoGeneral'].show();
        },

        seleccionarMenu(valor) {
            this.seleccion = valor
        },

        showContenidoAnalitico() {
            this.$refs['view-ContenidoAnalitico'].show();
        },

        AgregarCriterioDesempeio() {
            this.$refs['view-AgragarCriterioDesempenio'].show();
        },

        AgregarBibliografia() {
            this.$refs['view-AgregarBibliografia'].show();
        },

        updateCompetencia() {
            this.$refs['view-AgragarCompetencia'].show();
        },

        updateJustificacion() {
            this.$refs['view-AgragarJustificacion'].show();
        },

        updateCriteriosDesempenio() {
            this.$refs['view-AgregarCriteriosDesempenio'].show();
        },

        updateCargaHorariaCrtierio(criterio) {
            this.criterioCargaHoraria = criterio;
            this.$refs['view-CriterioCargaHoraria'].show();
        },
        cancelCargaHorariaCriterio() {
            this.$refs['view-CriterioCargaHoraria'].hide();

        },

        cancelarstoreCriterioDesempeño() {
            this.$refs['view-CriterioCYP'].hide();

        },

        updatePonderacionesYCronogramaCriterio(criterio) {
            this.criterioCYP = criterio;
            this.$refs['view-CriterioCYP'].show();
        },

        storeCriterioDesempeño(criterio) {
            this.ss.storeCriterioCompetencia(criterio).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.data.accion,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.$refs['view-CriterioCargaHoraria'].hide();
                    this.$refs['view-CriterioCYP'].hide();
                    this.getCriterios()
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        getCompetencias() {
            this.isLoading = true;
            console.log(this.parametro);
            this.ss.showCompetencia(this.parametro).then(
                (result) => {
                    let response = result;
                    if (response.data == null) {
                        this.competencia = {};
                        console.log('las competencia es null');
                    } else {
                        this.competencia = response.data[0];
                        console.log('COMPETENCIAAAAAA');
                        console.log(this.competencia);
                        this.ss.showCriteriosCompetencia(this.competencia).then(
                            (result) => {
                                let response = result;
                                if (response.data == null) {
                                    this.criterios = {};
                                    this.criterioInf = {};
                                } else {
                                    this.criterios = response.data;
                                    console.log('CRITERIOOOOSSSS');
                                    console.log(this.criterios);
                                    this.criterioInf = this.criterios[0];
                                    this.ss.getCriteriosContenido(this.criterios[0]).then(
                                        (result) => {
                                            let response = result;
                                            if (response.data == null) {
                                                this.contenido = {};
                                                this.contenidoInf = {};
                                            }
                                            else {
                                                this.contenido = response.data;
                                                console.log('CONTENIDOOOOOO');
                                                console.log(this.contenido);
                                                this.contenidoInf = this.contenido[0];
                                                this.criterioSeleccion = this.contenidoInf.id;
                                                this.ss.showContenidoAnalitico(this.contenido[0]).then(
                                                    (result) => {
                                                        let response = result;
                                                        if (response.data == null) {
                                                            this.contenidoAnalitico = {};
                                                        } else {
                                                            this.contenidoAnalitico = response.data;
                                                            console.log('contenidoAnalitico');
                                                            console.log(this.contenidoAnalitico);
                                                            this.isLoading = false;
                                                        }
                                                    }
                                                ).catch(error => {
                                                    console.log(error);
                                                    this.isLoading = false;
                                                });
                                            }



                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading = false;
                                    });

                                }



                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        });
                    }


                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        storeJustificacionMAteria() {
            console.log(this.materia);
            this.ss.updateMateriaSaga(this.materia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )

                    this.$refs['view-AgragarJustificacion'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        cancleJustificacionMAteria() {
            this.$refs['view-AgragarJustificacion'].hide();
        },

        storeCompetencia() {
            this.competencia.idMateria = this.parametro.idMateria;
            this.competencia.TituloCompetencia = 'N/A';
            console.log(this.competencia);
            this.ss.storeCompetencia(this.competencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-AgragarCompetencia'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        cancelStoreCompetencia() {
            this.$refs['view-AgragarCompetencia'].hide();
        },

        cancelModificarCriterios() {
            this.$refs['view-AgregarCriteriosDesempenio'].hide();
        },

        criterioSeleccionado() {
            this.count++;
            if (this.count == 2) {
                console.log('Estamos seleccionando el criterio');
                console.log(this.criterios);
                console.log(this.criterios[this.$refs.selectCriterioCompetencia.value]);
                this.count = 0;
                this.contenidoshow = this.criterios[this.$refs.selectCriterioCompetencia.value];
                this.criterioInf = this.criterios[this.$refs.selectCriterioCompetencia.value];

                for (let index = 0; index < this.contenido.length; index++) {
                    if (this.contenido[index].MateriaCriterioDesempeno_id == this.contenidoshow.id) {
                        this.contenidoInf = this.contenido[index];
                        index = this.contenido.length + 1;
                    }
                }

                this.ss.showContenidoAnalitico(this.contenidoInf).then(
                    (result) => {
                        let response = result;
                        this.contenidoAnalitico = response.data;
                        console.log('contenidoAnalitico');
                        console.log(this.contenidoAnalitico);
                        this.isLoading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });

            }

        },

        seleccionarCriterioMenuUpdate(seleccion) {
            console.log(seleccion);
            this.seleccionCriterioMenuUpdate = seleccion;
            this.layout2 = seleccion;
        },

        showMateria(item){
            console.log(id);
            this.ss.showMallaCurricular(item).then(
                (result) => {
                    let response = result;
                    this.materiaMalla = response.data;
                    console.log('materia ');
                    console.log(this.materiaMalla);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        }

    },
    components: {
        dataTable,
        Loader,
        CompetenciasUpdate,
        ContenidoUpdate,
        ContenidoAnaliticoUpdate,
        Resumen
    },
    props: {
        materiaProp: { default: "" },
    },
    mounted() {

        this.parametro = this.materiaProp;
        this.competencia = {};
        this.criterios = {};
        this.contenido = {};
        this.contenidoAnalitico = {};
        this.contenidoInf = {};
        this.criterioInf = {};


        var usuario = JSON.parse(localStorage.getItem('usuario'));
        this.auth = usuario;
        if (!usuario) {
            this.$router.push('/Login');
        } else {
            /* this.getMaterias();
            this.getEspecialidades();
            this.getUnidadesAcademicas(); */
            console.log(this.parametro);
            this.getCompetencias();
            
        }
    }
};